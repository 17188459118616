import React, { Component, useEffect, useState } from 'react';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {useHistory, Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination,Scrollbar } from 'swiper/modules';
import Parser from 'html-react-parser';
import LazyLoad from 'react-lazyload';
import Product from '../Produs';
import $ from 'jquery';
import axios from 'axios'; 
import { NumericFormat } from 'react-number-format';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';



const ColectieProduse = props => {
  const [name, setName]=useState([]);
  const [items, setItems]=useState([]);
  const [isLoaded, setIsLoaded]=useState(true);
  const [crurl, setCrurl]=useState();
  const [iName, setIName]=useState(props.name);
  const [title, setTitle]=useState(props.title);
  const [moneda, setMoneda]=useState(window.moneda);
  const [lang_code, setLangCode]=useState(window.lang_code);
  const [sel_prod, setSelProd]=useState(0);
  const [prod_det, setProdDet]=useState([]);
  const [isBoxVisible, setIsBoxVisible]=useState(false);
  const [loadp, setLoadP]=useState(false);
  const [slides, setSlides]=useState([]);
  const [loc1, setLoc1]=useState(window.location.href);
  const [locrec, setLocRec1]=useState();

  
  
  //let slides=[];

  const openTooltip = async (event, product_id, url,image) => {
    if(window.innerWidth<768){
      event.preventDefault();
      //console.log(product_id);
      
        /*
        $.getJSON("index.php?dispatch=products.quick_view&product_id="+product_id+"", { is_ajax: 1}, function(data){
                    $('#products_'+iName+' .mht').html(data.text);
                });  
                */
                $('#products_'+iName+' .mht').html();
                setIsBoxVisible(true);
                
                //setLoadP(false);
              
               
              await fetch('index.php?dispatch=react.product&product_id='+product_id) 
                  .then(res => res.json())
                  .then(
                    (result) => {
                      //console.log('zzz');
                      //console.log(result);
                      //console.log('zzz');
                      setSelProd(product_id);
                      setProdDet(result);
                     
                     
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                      //console.log(error)
                    }
                  )
                
       // this.props.history.replace({ pathname: `/product/${this.props.product.id}`})
       if(props.name!='similare'){
        window.history.pushState(product_id, 'Title', url);
        }
      
         event.preventDefault();
    }else{
      
      //browserHistory.push(url);
            document.getElementsByClassName('breadcrumbs-grid')[0].style.display = 'none';
          
            //console.log(product_id);
            
              /*
              $.getJSON("index.php?dispatch=products.quick_view&product_id="+product_id+"", { is_ajax: 1}, function(data){
                          $('#products_'+iName+' .mht').html(data.text);
                      });  
                     
                      $('#products_'+iName+' .mht').html();
                    this.setState({
                      isBoxVisible: false,
                      loadp: false
                    });
                    
                    
                    await fetch('products.php?product_id='+product_id) 
                        .then(res => res.json())
                        .then(
                          (result) => {
                            
                            //console.log(result);
                            this.setState({
                              sel_prod: product_id,
                              prod_det: result,
                              imfirst: image,
                              imfirst: image,
                              loadedtp:'product',
                              loadp: true
                            });
                            
                          
                          },
                          // Note: it's important to handle errors here
                          // instead of a catch() block so that we don't swallow
                          // exceptions from actual bugs in components.
                          (error) => {
                            //console.log(error)
                          }
                        ) */
                      
            // this.props.history.replace({ pathname: `/product/${this.props.product.id}`})
            window.history.pushState(product_id, 'Title', url);
            
        
          }
          //console.log(window.innerWidth);

      }

      const closeTooltip = (event, product_id) => {
        //console.log(product_id);
     
        setIsBoxVisible(false);
        setLoadP(false);
        //setProdDet([]);
        setSelProd(0);
        
        
        window.history.pushState(product_id, 'Title', crurl);
        //console.log(window.location.href);
        //console.log(crurl);
       // $('#products_'+iName+' .mht').html('');
        event.preventDefault();

      }
       
      window.addEventListener('popstate', (event) => {
        event.preventDefault();
        let loc=window.location.href;
        let locrec=loc.split('/p/');
            //console.log(event.state);
            //console.log('backda');
            setIsBoxVisible(false);
            setLoadP(false);
            setProdDet([]);
            setSelProd(0);
            
            $('#products_'+iName+' .mht').html('');
        
        
       }, false);
       /*
      window.history.pushState({name: "browserBack"}, "on browser back click",loc1);
      window.history.pushState({name: "browserBack"}, "on browser back click", loc1);
       */


  useEffect(() => {
    //console.log(props);
   
   
    let slides2=[];
    let produse1=props?.items;
   
    let produse=produse1?.split(",");
    //console.log(produse);
    if(produse){
   
      ////console.log(produse);
      produse.forEach((item) => { item=item.split('|'); console.log(item)
          slides2.push(
            <SwiperSlide key={item[1]} className={`col  ${item.produsul_zilei} `}>
              <div >
           
           
                   <div className="colectie__image" >
                   <LazyLoadImage  src={item[2].replace('550/794/', '50/72/')} delayTime={5} threshold={300} visibleByDefault={true}/>
                   </div>
                  
           
         </div>
            </SwiperSlide>
          )
          });
      }
      setSlides(slides2);
     
  }, [props]);
 
 
  ////console.log(imagini);
  return (
    <>
    {isLoaded===false ?
      <div>
        loading
      </div>
      :
      <>
     
        <Swiper
            scrollbar={false}
            className="mySwiper"
            slidesPerView="4"
            breakpoints={{
              768: {
                slidesPerView: "5",
              }
            }}
            rebuildOnUpdate="true"
           
          >
          {slides}
            
          </Swiper>

        </> 
            }
          </> 
  );
};

export default ColectieProduse;